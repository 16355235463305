@import url("https://fonts.googleapis.com/css?family=Mohave:400,500,700,600|Playfair+Display:500,700,600|Josefin+Sans:400");
@import url("https://cdnjs.cloudflare.com/ajax/libs/meyer-reset/2.0/reset.min.css");
      * {
        -webkit-font-smoothing: antialiased;
        box-sizing: border-box;
      }
      html,
      body {
        margin: 0px;
        height: 100%;
      }
    
      button:focus-visible {
        outline: 2px solid #4a90e2 !important;
        outline: -webkit-focus-ring-color auto 5px !important;
      }
      a {
        text-decoration: none;
      }

      .custom-underline-offset {
        text-decoration-thickness: 2px; /* Adjust thickness if needed */
        text-underline-offset: 50%; /* Move the underline 50% below */
      }